import { ComponentType } from "react";
import { FormattedMessage } from "react-intl";

import { IconButton } from "../forms";
import { FileCopyIcon, IconProps } from "../icons";
import { Position, Tooltip } from "../tooltip";
import { Typography } from "../typography";
import { useCopyToClipboard } from "../utils/useCopyToClipboard";

export const COPY = {
  INITIAL: <FormattedMessage defaultMessage="Copy" id="4l6vz1" />,
  COPIED: <FormattedMessage defaultMessage="Copied to clipboard" id="93oVTh" />,
  NON_APPLICABLE: <FormattedMessage defaultMessage="Non-applicable" id="OVMQSj" />,
} as const;

export function CopyButton({
  textToCopy = "",
  enabled = true,
  tooltipPosition = "right-start",
  icon: Icon = FileCopyIcon,
  variant = "DEFAULT",
}: {
  textToCopy: string | undefined;
  enabled?: boolean;
  tooltipPosition?: Position;
  icon?: ComponentType<IconProps>;
  variant?: "DEFAULT" | "LIGHT";
}) {
  const { copyState, setCopyState, onCopy } = useCopyToClipboard({
    initialState: enabled ? "INITIAL" : "NON_APPLICABLE",
  });

  return (
    <Tooltip
      mode="dark"
      size="small"
      offset={[0, 15]}
      widthClassName="min-w-min"
      position={tooltipPosition}
      panelPadding="1.5"
      onTooltipClose={() => setCopyState("INITIAL")}
      body={<Typography color="text-white">{COPY[copyState]}</Typography>}
      // eslint-disable-next-line react/no-children-prop
      children={
        enabled ? (
          <IconButton
            size="EXTRA_SMALL"
            variant={variant === "DEFAULT" ? "COPY" : "COPY_LIGHT"}
            icon={Icon}
            onClick={() => onCopy(textToCopy)}
          />
        ) : undefined
      }
    />
  );
}
