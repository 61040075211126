import { useMemo } from "react";

import { IProduct, ISubproduct, Product, Products } from "@/lib/product";

import ProductCard from "./ProductCard";

const PRODUCT_ONCLICK_MAPS: Record<Product, string> = {
  [Products.TAX]: "/tax",
  [Products.LOOKUP]: "/lookup",
  [Products.INVOICING]: "/invoicing",
  [Products.REPORTING]: "/reporting",
  [Products.RETURNS]: "/returns",
  [Products.EINVOICING]: "/einvoicing",
};

interface Props {
  products: IProduct[];
  subproducts: ISubproduct[];
  onOpenProduct: (product: string) => void;
  isLegacyReportingTenant: boolean;
}

const ProductCards = ({ products, subproducts, onOpenProduct, isLegacyReportingTenant }: Props) => {
  const isLookupTinSearchActive = subproducts.some(
    ({ subproduct, isActive }) => subproduct === "reverse_lookup" && isActive
  );

  const filteredProducts = useMemo(() => {
    // E-invoicing is active if ui is not a legacy reporting tenant and invoicing or reporting is active
    const isEInvoicingActive =
      !isLegacyReportingTenant &&
      products.some(
        (fp) =>
          fp.isActive && (fp.product === Products.INVOICING || fp.product === Products.REPORTING)
      );

    const filtered = products.filter(
      ({ product }) =>
        // Exclude e-invoicing from reporting legacy tenants
        (isLegacyReportingTenant && product !== Products.EINVOICING) ||
        // Exclude invoicing and reporting from non legacy tenants
        (!isLegacyReportingTenant &&
          ![Products.INVOICING, Products.REPORTING].some((p) => p === product))
    );

    const eInvoicingProductIx = filtered.findIndex((fp) => fp.product === Products.EINVOICING);
    // If the e-invoicing product is present, set it as active if invoicing or reporting is active
    if (eInvoicingProductIx > -1) {
      filtered[eInvoicingProductIx].isActive = isEInvoicingActive;
    }

    return filtered;
  }, [isLegacyReportingTenant, products]);

  return (
    <div>
      {filteredProducts.map(({ isActive, product }) => {
        return (
          <ProductCard
            key={product}
            spacing={{ mt: 6 }}
            type={product}
            isActive={isActive || (product === Products.LOOKUP && isLookupTinSearchActive)}
            onClickOpen={() => onOpenProduct(PRODUCT_ONCLICK_MAPS[product])}
          />
        );
      })}
    </div>
  );
};

export default ProductCards;
