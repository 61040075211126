import { Button } from "@fonoa/ui-components/button";
import {
  ProductIconProps,
  ProductInvoicingIcon,
  ProductLookupIcon,
  ProductReportingIcon,
  ProductReturnsIcon,
  ProductTaxIcon,
} from "@fonoa/ui-components/icons";
import { SpacingProps } from "@fonoa/ui-components/types";
import { A, Typography } from "@fonoa/ui-components/typography";
import classNames from "classnames";
import Image from "next/image";
import { ComponentType } from "react";
import { FormattedMessage } from "react-intl";

import Pill, { ColorVariant, SizeVariant } from "@/components/Labels/Pill";
import { ImageType, useImages } from "@/components/Utils/Images";
import { Product, Products } from "@/lib/product";

const PRODUCT_ICON_MAPS: Record<Product, ComponentType<ProductIconProps>> = {
  [Products.TAX]: ProductTaxIcon,
  [Products.LOOKUP]: ProductLookupIcon,
  [Products.INVOICING]: ProductInvoicingIcon,
  [Products.REPORTING]: ProductReportingIcon,
  [Products.RETURNS]: ProductReturnsIcon,
  [Products.EINVOICING]: ProductInvoicingIcon,
};

const PRODUCT_IMAGES_MAPS: Record<Product, ImageType> = {
  [Products.TAX]: "productTax",
  [Products.LOOKUP]: "productLookup",
  [Products.INVOICING]: "productInvoicing",
  [Products.REPORTING]: "productReporting",
  [Products.RETURNS]: "productReturns",
  [Products.EINVOICING]: "productEInvoicing",
};

const PRODUCT_API_DOCUMENT_MAPS: Record<Product, string> = {
  [Products.TAX]: "https://docs.fonoa.com/reference/about-fonoa-tax",
  [Products.LOOKUP]: "https://docs.fonoa.com/reference/about-fonoa-lookup",
  [Products.INVOICING]: "https://docs.fonoa.com/reference/about-fonoa-e-invoicing",
  [Products.REPORTING]: "https://docs.fonoa.com/reference/about-fonoa-e-invoicing",
  [Products.RETURNS]: "https://fonoa.com/",
  [Products.EINVOICING]: "https://docs.fonoa.com/reference/about-fonoa-e-invoicing",
};
interface IProps extends SpacingProps {
  type?: Product;
  isActive?: boolean;
  onClickOpen?: () => void;
  onClickViewApiKey?: () => void;
}

export default function ProductCard({
  type = Products.TAX,
  isActive = false,
  onClickOpen,
  onClickViewApiKey,
}: IProps) {
  const IconProduct = PRODUCT_ICON_MAPS[type];

  return (
    <div className="relative isolate mt-6 overflow-hidden rounded border border-blueGray200 bg-blueGray10 pt-4 pl-6 sm:px-6 lg:flex lg:gap-x-20 lg:pl-8">
      <div className="mx-0 flex-auto py-4 text-left lg:max-w-lg lg:py-6">
        <div className="flex items-center">
          <IconProduct
            className="mr-1.5"
            variant={isActive ? "active" : "default"}
            size={24}
            overrideTextColor={type === Products.REPORTING || type === Products.INVOICING}
          />
          <Typography color="text-primaryBlue900" fontSize="text-lg">
            {type === Products.TAX && <FormattedMessage defaultMessage="Tax" id="AwzkSM" />}
            {type === Products.LOOKUP && <FormattedMessage defaultMessage="Lookup" id="VzW9jr" />}
            {type === Products.INVOICING && (
              <FormattedMessage defaultMessage="Invoicing" id="ogLSJ3" />
            )}
            {type === Products.REPORTING && (
              <FormattedMessage defaultMessage="Reporting" id="reFEEC" />
            )}
            {type === Products.RETURNS && <FormattedMessage defaultMessage="Returns" id="WuGkfJ" />}
            {type === Products.EINVOICING && (
              <FormattedMessage defaultMessage="e-Invoicing" id="6BCwDp" />
            )}
          </Typography>
          <Pill
            type={SizeVariant.COLOURS}
            color={isActive ? ColorVariant.SUCCESS : ColorVariant.DISABLED}
            spacing={{ ml: 4 }}
          >
            {isActive ? (
              <FormattedMessage defaultMessage="Active" id="3a5wL8" />
            ) : (
              <FormattedMessage defaultMessage="Inactive" id="6Tps09" />
            )}
          </Pill>
        </div>
        <div className="mt-4">
          <Typography lineHeight="leading-6" color="text-blueGray700">
            {type === Products.TAX && (
              <FormattedMessage
                defaultMessage="Allows you to automatically determine the tax treatment of both domestic and cross-border transactions across 190+ countries globally. Fonoa Tax takes into account all relevant transaction variables, including monitoring VAT/GST thresholds, service categories, suppliers’ and customers’ tax status, and any potential marketplace liability arising when transactions happen via a platform/marketplace."
                id="M+cCBI"
              />
            )}
            {type === Products.LOOKUP && (
              <FormattedMessage
                defaultMessage="Allows you to validate tax identification numbers (TINs) by format, syntax, and via live validations against relevant government databases. This ensures the TIN provided to you by customers and/or suppliers is valid and that you are dealing with a VAT/GST-registered party."
                id="w9tJ6w"
              />
            )}
            {type === Products.INVOICING && (
              <FormattedMessage
                defaultMessage="Automatically generates locally-compliant tax invoices for transactions across the World. You can create invoices in 50+ languages including English, Spanish, Italian, French, German, Thai, and Japanese."
                id="7f29yL"
              />
            )}
            {type === Products.REPORTING && (
              <FormattedMessage
                defaultMessage="Allows you to report sales transactions to tax authorities in real-time. Before Fonoa starts to report sales transactions on your behalf, you must provide accurate information about the business engaged in the sales transactions that will be reported through the platform. This is a one time process called onboarding."
                id="uLJtQg"
              />
            )}
            {type === Products.RETURNS && (
              <FormattedMessage
                defaultMessage="Automatically prepare, review and submit error-free, VAT/GST returns everywhere from one single platform."
                id="HwwKrs"
              />
            )}
            {type === Products.EINVOICING && (
              <FormattedMessage
                defaultMessage="Generate compliant invoices worldwide, reporting sales transactions to tax authorities in real-time where mandated by tax authorities. Before Fonoa starts to report sales transactions and generate compliant templates for you, you must provide accurate information about the business engaged in the sales transactions. This is a one time process called onboarding."
                id="yqWQrZ"
              />
            )}
          </Typography>
        </div>
        <div className="mt-10 flex items-center justify-start gap-x-6">
          <div className="w-40">
            {isActive ? (
              <Button
                dataAttributes={{
                  testid: `open-products-${type}`,
                  cy: `open-products-${type}`,
                }}
                fluid
                variant="FILLED"
                onClick={onClickOpen}
              >
                <span className="text-xs">
                  {type === Products.TAX && (
                    <FormattedMessage defaultMessage="Open Tax" id="WwbAWw" />
                  )}
                  {type === Products.LOOKUP && (
                    <FormattedMessage defaultMessage="Open Lookup" id="U5UpfS" />
                  )}
                  {type === Products.INVOICING && (
                    <FormattedMessage defaultMessage="Open Invoicing" id="vv+xFs" />
                  )}
                  {type === Products.REPORTING && (
                    <FormattedMessage defaultMessage="Open Reporting" id="1T3HsC" />
                  )}
                  {type === Products.RETURNS && (
                    <FormattedMessage defaultMessage="Open Returns" id="HIwZr+" />
                  )}
                  {type === Products.EINVOICING && (
                    <FormattedMessage defaultMessage="Open e-Invoicing" id="J3F3jN" />
                  )}
                </span>
              </Button>
            ) : (
              <a
                data-cy={`contact-sales-products-${type}`}
                href="https://www.fonoa.com/contact-sales"
                target="_blank"
                className="group flex h-10 items-center justify-center whitespace-nowrap rounded border-2 border-primaryBlue500 bg-primaryBlue500 py-1 px-4 text-sm font-medium leading-7 text-white hover:border-primaryBlue600 hover:bg-primaryBlue600 focus:border-2 focus:border-blueGray300 focus:bg-primaryBlue500 focus:outline-none active:border-primaryBlue600 active:bg-primaryBlue700"
                rel="noreferrer"
              >
                <FormattedMessage defaultMessage="Contact Sales" id="8oPf1o" />
              </a>
            )}
          </div>

          {onClickViewApiKey && (
            <div className="mt-4 sm:mt-0 sm:ml-6">
              <Button variant="LINK" size="SMALL" onClick={onClickViewApiKey}>
                <FormattedMessage defaultMessage="View API Key" id="2QKkuF" />
              </Button>
            </div>
          )}
          {type !== Products.RETURNS && PRODUCT_API_DOCUMENT_MAPS[type] && (
            <A
              href={PRODUCT_API_DOCUMENT_MAPS[type]}
              external
              fontWeight="normal"
              className="mt-4 text-sm sm:mt-0 sm:ml-7"
            >
              {type === Products.EINVOICING ? (
                <FormattedMessage defaultMessage="Learn more about e-Invoicing" id="dvIRIN" />
              ) : (
                <FormattedMessage defaultMessage="Read API Documentation" id="36XHbD" />
              )}
            </A>
          )}
        </div>
      </div>
      <ProductImage type={type} />
    </div>
  );
}

const ProductImage = ({ type }: { type: Product }) => {
  const Images = useImages();

  // E-invoicing has a different width because it has a new image design.
  const classWidth = type === Products.EINVOICING ? "w-[36.5rem]" : "w-[38rem]";

  return (
    <div className="relative mt-4 hidden h-28 lg:block">
      <Image
        className={classNames(
          "absolute top-0 left-0 w-[38rem] max-w-none rounded-lg bg-white/5 ring-1 ring-white/10",
          classWidth
        )}
        src={Images[PRODUCT_IMAGES_MAPS[type]].src}
        alt={Images[PRODUCT_IMAGES_MAPS[type]].alt}
        width={1200}
        height={749}
        priority={type === Products.LOOKUP}
      />
    </div>
  );
};

ProductCard.type = Products;
