import { CircleCloseIcon, IconProps } from "@fonoa/ui-components/icons";
import { SpacingProps } from "@fonoa/ui-components/types";
import { getSpacingClass } from "@fonoa/ui-components/utils";
import classNames from "classnames";
import { ComponentType, ReactNode } from "react";

import Avatar from "@/components/Avatar";
import { Size } from "@/components/Avatar/Avatar";

export const SizeVariant = {
  REGULAR: "REGULAR",
  SMALL: "SMALL",
  COLOURS: "COLOURS",
} as const;
export type SizeVariant = typeof SizeVariant[keyof typeof SizeVariant];

export const ColorVariant = {
  VERIFIED: "VERIFIED",
  SUCCESS: "SUCCESS",
  ALERT: "ALERT",
  WARNING: "WARNING",
  NOT_FOUND: "NOT_FOUND",
  INACTIVE: "INACTIVE",
  DISABLED: "DISABLED",
} as const;

export type ColorVariant = typeof ColorVariant[keyof typeof ColorVariant];

export const StatusVariant = {
  DEFAULT: "Default",
  ACTIVE: "Active",
  SELECTED: "Selected",
} as const;

export type StatusVariant = typeof StatusVariant[keyof typeof StatusVariant];

const TEXT_SIZE_VARIANT_MAPS: Record<SizeVariant, string> = {
  [SizeVariant.REGULAR]: "text-sm font-normal h-8 px-3 py-1",
  [SizeVariant.SMALL]: "text-xs font-normal h-6 px-3 py-1",
  [SizeVariant.COLOURS]: "text-[8px] leading-4 font-medium h-4 px-2 py-0",
};

const COLOR_VARIANT_MAPS: Record<StatusVariant | ColorVariant, string> = {
  [StatusVariant.DEFAULT]: "bg-blueGray25",
  [StatusVariant.ACTIVE]: "bg-blueGray200",
  [StatusVariant.SELECTED]: "text-primaryBlue700 bg-primaryBlue10",
  [ColorVariant.VERIFIED]: "text-primaryBlue700 bg-primaryBlue10",
  [ColorVariant.SUCCESS]: "text-green700 bg-green10",
  [ColorVariant.ALERT]: "text-red700 bg-red10",
  [ColorVariant.WARNING]: "text-yellow800 bg-yellow10",
  [ColorVariant.NOT_FOUND]: "text-blueGray600 bg-blueGray25",
  [ColorVariant.INACTIVE]: "text-blueGray600 bg-blueGray100",
  [ColorVariant.DISABLED]: "text-blueGray700 bg-blueGray400",
};

// Ensure `avatarImgAlt` is required when `avatarImgUrl` is set
type AvatarImgProps =
  | {
      avatarImgUrl: string;
      avatarImgAlt: string;
    }
  | {
      avatarImgUrl?: never;
      avatarImgAlt?: never;
    };

export type PillProps = SpacingProps &
  AvatarImgProps & {
    children?: ReactNode;
    type?: SizeVariant;
    status?: StatusVariant;
    color?: ColorVariant;
    leftIcon?: ComponentType<IconProps>;
    rightIcon?: ComponentType<IconProps>;
    uppercase?: boolean;
    onRemoveClick?: () => void;
    onClick?: () => void;
  };

export default function Pill({
  children,
  type = SizeVariant.REGULAR,
  status = StatusVariant.DEFAULT,
  color = ColorVariant.VERIFIED,
  uppercase = false,
  leftIcon: LeftIconComponent,
  rightIcon: RightIconComponent,
  avatarImgUrl,
  avatarImgAlt,
  onRemoveClick,
  onClick,
  spacing,
}: PillProps) {
  const closeIconSize: number = type === SizeVariant.COLOURS ? 10 : 12;
  const iconSize: number = type === SizeVariant.REGULAR ? 12 : type === SizeVariant.SMALL ? 9 : 8;

  const avatarSize: Size = type === SizeVariant.REGULAR ? "2xs" : "3xs";

  return (
    <div
      className={classNames(
        "flex items-center whitespace-nowrap rounded-full text-justify",
        TEXT_SIZE_VARIANT_MAPS[type],
        type === SizeVariant.COLOURS ? COLOR_VARIANT_MAPS[color] : COLOR_VARIANT_MAPS[status],
        getSpacingClass(spacing),
        {
          "pl-2.5": LeftIconComponent && type !== SizeVariant.COLOURS,
          "pl-2": LeftIconComponent && type === SizeVariant.COLOURS,
          "pr-2.5": (RightIconComponent || onRemoveClick) && type === SizeVariant.REGULAR,
          "pr-1.5": (RightIconComponent || onRemoveClick) && type !== SizeVariant.REGULAR,
          "pl-1.5": avatarImgUrl,
          "text-blueGray700": type !== SizeVariant.COLOURS && status !== StatusVariant.SELECTED,
          "cursor-pointer": onClick,
        }
      )}
      onClick={onClick}
    >
      {LeftIconComponent && (
        <LeftIconComponent
          className={classNames(`mr-2`, {
            "mr-1.5": type === SizeVariant.COLOURS,
          })}
          size={iconSize}
        />
      )}
      {!LeftIconComponent && avatarImgUrl && (
        <Avatar img={avatarImgUrl} spacing={{ mr: 2 }} size={avatarSize} alt={avatarImgAlt} />
      )}
      <span
        className={classNames("flex items-center", {
          "pt-px": type === SizeVariant.COLOURS,
          uppercase: type === SizeVariant.COLOURS || uppercase,
        })}
      >
        {children}
      </span>
      {onRemoveClick ? (
        <CircleCloseIcon
          className={classNames(`ml-2`, {
            "ml-1.5": type === SizeVariant.SMALL || type === SizeVariant.COLOURS,
          })}
          size={closeIconSize}
        />
      ) : (
        RightIconComponent && <RightIconComponent className="ml-2" size={iconSize} />
      )}
    </div>
  );
}

Pill.type = SizeVariant;
Pill.color = ColorVariant;
