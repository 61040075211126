import copy from "copy-to-clipboard";
import { useCallback, useEffect, useRef, useState } from "react";
import { FormattedMessage } from "react-intl";

export const COPY = {
  INITIAL: <FormattedMessage defaultMessage="Copy" id="4l6vz1" />,
  COPIED: <FormattedMessage defaultMessage="Copied to clipboard" id="93oVTh" />,
  NON_APPLICABLE: <FormattedMessage defaultMessage="Non-applicable" id="OVMQSj" />,
} as const;

type Props = {
  timeoutDelay?: number;
  initialState?: keyof typeof COPY;
};

export function useCopyToClipboard(props?: Props) {
  const { timeoutDelay = 2500, initialState = "INITIAL" } = props || {};
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();
  const [state, setState] = useState<keyof typeof COPY>(initialState);

  useEffect(() => {
    return () => clearTimeout(timeoutRef.current);
  }, []);

  const onCopy = useCallback(
    (text: string) => {
      copy(text);
      setState("COPIED");

      timeoutRef.current = setTimeout(() => {
        setState("INITIAL");
      }, timeoutDelay);
    },
    [timeoutDelay]
  );

  return {
    copyState: state,
    setCopyState: setState,
    onCopy,
  };
}
